import React from "react";
import { Helmet } from "react-helmet";

const Resume = () => {
  return (
    <>
      <Helmet>
        <title>My Resume</title>
      </Helmet>
      <iframe
        src="/Rushiraj_Herma_Resume.pdf"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          width: "100%",
          height: "100%",
          border: "none",
          margin: 0,
          padding: 0,
          overflow: "hidden",
        }}
        title="Resume"
      />
    </>
  );
};

export default Resume;
